<template>
  <div>
    <app-login-navigation />
    <app-form-forgot class="mt-9" />
  </div>
</template>

<script>
export default {
  name: 'ForgotPassword'
}
</script>
